import { nanoid } from 'nanoid'
import { paddingLeft } from '../utils/methods'
import Photo from './Photo'
import { Group as GroupType } from '../utils/Types'

export default class Group implements GroupType {
  id: string
  name: string
  photos: Photo[]
  photoCnt: number
  isHeadBarcode: boolean
  isError: boolean

  constructor(group?: GroupType) {
    const date = new Date()

    this.id = group ? group.id : nanoid(20)
    this.name = group
      ? group.name
      : 'group_' +
        paddingLeft(date.getHours(), '0', 2) +
        paddingLeft(date.getMinutes(), '0', 2) +
        paddingLeft(date.getSeconds(), '0', 2)
    this.photos = []
    if (group) {
      for (const photo of group.photos) {
        this.photos.push(new Photo(photo))
      }
    }

    this.photoCnt = group ? group.photoCnt : 0
    this.isHeadBarcode = group ? group.isHeadBarcode : false
    this.isError = group ? group.isError : false
  }

  static createGroups = (groups: GroupType[]): Group[] => {
    return groups.map((group) => {
      return new Group(group)
    })
  }

  clone = (): Group => {
    return new Group(this)
  }

  addPhoto = (src: string): void => {
    this.photos.push(
      new Photo({
        name: paddingLeft(
          this.photos.length + (this.isHeadBarcode ? 0 : 1),
          '0',
          3
        ),
        src: src,
      })
    )
    this.photoCnt += 1
  }

  duplicatePhoto = (photoId: string): void => {
    const photoIndex = this.photos.findIndex((photo) => photo.id === photoId)

    const dupPhoto = new Photo({
      name: this.photos[photoIndex].name,
      src: this.photos[photoIndex].src,
      colorSetting: this.photos[photoIndex].colorSetting,
    })

    this.photos = [
      ...this.photos.slice(0, photoIndex + 1),
      dupPhoto,
      ...this.photos.slice(photoIndex + 1),
    ]

    const offset = this.isHeadBarcode ? 0 : 1
    this.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + offset, '0', 3)
    })
  }

  deletePhoto = (id: string): void => {
    this.photos = this.photos.filter((photo) => photo.id !== id)

    const offset = this.isHeadBarcode ? 0 : 1
    this.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + offset, '0', 3)
    })
  }

  toggleIsHeadBarcode = (): void => {
    this.isHeadBarcode = !this.isHeadBarcode
    const offset = this.isHeadBarcode ? 0 : 1
    this.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + offset, '0', 3)
    })
  }
}
