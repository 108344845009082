import React, { useEffect, useRef, useState } from 'react'

import { AppState } from '../../redux/store'
import { useSelector } from 'react-redux'

import { Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import {
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
  Tune as TuneIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons'

import { Photo as PhotoClass } from '../../classes/'
import { ColorSetting } from '../../components/'
import {
  ColorSetting as ColorSettingType,
  WatermarkInfo,
} from '../../utils/Types'
import { THUMBNAIL_SIZE } from '../../utils/constant'
import { asyncImageReader } from '../../utils/methods'
import { hsvAdjustment } from '../../utils/imageProcessing'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      margin: '0 10px 10px 0',
    },
    title: {},
    img: {
      maxWidth: THUMBNAIL_SIZE,
      maxHeight: THUMBNAIL_SIZE,
      objectFit: 'contain',
    },
    photo: {
      padding: '5px',
      width: THUMBNAIL_SIZE,
      height: '185px',
      display: 'inline-block',
      position: 'relative',
    },
    overlay: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      opacity: 0,
      flexDirection: 'column',
      alignItems: 'flex-end',
      '&:hover': {
        opacity: 1,
      },
    },
    actionIcon: {
      width: '40px',
      height: '40px',
      marginRight: '5px',
    },
    enlargeBody: {
      position: 'relative',
      '&:focus': {
        outline: 'none',
      },
    },
    enlargeImg: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    enlargeWatermark: {
      position: 'absolute',
    },
    imageContainer: {
      marginTop: '2px',
      position: 'relative',
    },
    watermark: {
      position: 'absolute',
    },
  })
)

const getWatermarkStyle = (
  watermarkInfo: WatermarkInfo,
  previewSize: number
) => {
  let ratio = 0
  if (watermarkInfo.previewSize.width < watermarkInfo.previewSize.height) {
    ratio = previewSize / watermarkInfo.previewSize.height
  } else {
    ratio = previewSize / watermarkInfo.previewSize.width
  }

  return {
    opacity: watermarkInfo.opacity / 100,
    width: watermarkInfo.size.width * ratio,
    height: watermarkInfo.size.height * ratio,
    top: watermarkInfo.position.y * ratio,
    left: watermarkInfo.position.x * ratio,
  }
}

type ThumbnailProps = {
  src: string
  size: number
  hsl: ColorSettingType
}
const Thumbnail: React.FC<ThumbnailProps> = (props: ThumbnailProps) => {
  const hsl = props.hsl

  const canvasElement = useRef<HTMLCanvasElement>(
    document.createElement('canvas')
  )

  const [imageData, setImageData] = useState<ImageData>()

  useEffect(() => {
    ;(async () => {
      const ctx = canvasElement.current.getContext('2d')
      if (ctx === null) {
        return
      }

      let newImageData = imageData
      if (!imageData) {
        const image = await asyncImageReader(props.src)
        let width = props.size
        let height = props.size
        if (image.width < image.height) {
          width *= image.width / image.height
        } else {
          height *= image.height / image.width
        }

        canvasElement.current.width = width
        canvasElement.current.height = height

        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          0,
          0,
          width,
          height
        )

        newImageData = ctx.getImageData(0, 0, width, height)
        setImageData(newImageData)
      }

      if (newImageData) {
        if (!(hsl.h === 50 && hsl.s === 50 && hsl.l === 50)) {
          newImageData = hsvAdjustment(newImageData, {
            h: hsl.h,
            s: hsl.s,
            l: hsl.l,
          })
        }

        ctx.putImageData(newImageData, 0, 0)
      }
    })()
  }, [hsl])

  return <canvas ref={canvasElement} />
}

type Props = {
  photo: PhotoClass
  onEnlargePhoto: (photo: PhotoClass) => void
  onSplitGroup: (id: string) => void
  onDuplicatePhoto: (id: string) => void
  onDeletePhoto: (id: string) => void
  onColorChange: (id: string, colorSetting: ColorSettingType) => void
}

const Photo: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const [isColorOpen, setIsColorOpen] = useState(false)

  const canvasElement = useRef<HTMLCanvasElement>(
    document.createElement('canvas')
  )

  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )

  const photoId = props.photo.id
  const photoName = props.photo.name
  const onSplitGroup = props.onSplitGroup
  const onDuplicatePhoto = props.onDuplicatePhoto
  const onDeletePhoto = props.onDeletePhoto
  const onColorChange = props.onColorChange

  useEffect(() => {
    ;(async () => {
      canvasElement.current.width = THUMBNAIL_SIZE
      canvasElement.current.height = THUMBNAIL_SIZE

      const ctx = canvasElement.current.getContext('2d')
      if (ctx === null) {
        return
      }

      const img = await asyncImageReader(props.photo.src)
      ctx.drawImage(
        img,
        0,
        0,
        watermarkInfo.previewSize.width,
        watermarkInfo.previewSize.height,
        0,
        0,
        THUMBNAIL_SIZE,
        THUMBNAIL_SIZE
      )

      const data = ctx.getImageData(0, 0, THUMBNAIL_SIZE, THUMBNAIL_SIZE)
      const rgbData: number[] = []
      for (let i = 0; i < THUMBNAIL_SIZE * THUMBNAIL_SIZE * 4; i += 4) {
        const r = data.data[i]
        const g = data.data[i + 1]
        const b = data.data[i + 2]

        rgbData.push(r, g, b)
      }

      ctx.putImageData(data, 0, 0)
    })()
  }, [canvasElement])

  const toggleColorOpen = (): void => {
    setIsColorOpen(!isColorOpen)
  }

  const onEnlargePhoto = (): void => {
    props.onEnlargePhoto(props.photo)
  }

  const onSplitHandler = React.useCallback((): void => {
    onSplitGroup(photoId)
  }, [onSplitGroup, photoId])

  const onDuplicateHandler = React.useCallback((): void => {
    onDuplicatePhoto(photoId)
  }, [onDuplicatePhoto, photoId])

  const onDeleteHandler = React.useCallback((): void => {
    onDeletePhoto(photoId)
  }, [onDeletePhoto, photoId])

  const onColorHandler = React.useCallback(
    (colorSetting: ColorSettingType): void => {
      onColorChange(photoId, colorSetting)
      toggleColorOpen()
    },
    [photoId, toggleColorOpen]
  )

  const Watermark = React.useCallback(
    ({ className }: { className: string }): React.ReactElement => {
      if (photoName !== '001' || watermarkInfo.src === '') {
        return <></>
      }

      const style = getWatermarkStyle(watermarkInfo, THUMBNAIL_SIZE)

      return (
        <img
          style={style}
          src={watermarkInfo.src}
          className={className}
          alt="watermark"
        />
      )
    },
    [photoName, watermarkInfo]
  )

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.photo}>
        <span className={classes.title}>{props.photo.name}</span>
        <div className={classes.imageContainer}>
          {/* <canvas ref={canvasElement} /> */}
          <Thumbnail
            src={props.photo.src}
            size={THUMBNAIL_SIZE}
            hsl={props.photo.colorSetting}
          />
          <Watermark className={classes.watermark} />
        </div>
        <div className={classes.overlay}>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="拡大"
            onClick={onEnlargePhoto}
          >
            <ZoomOutMapIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="グループ分割"
            onClick={onSplitHandler}
          >
            <SubdirectoryArrowRightIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="複製"
            onClick={onDuplicateHandler}
          >
            <FileCopyIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="色調補正"
            onClick={toggleColorOpen}
          >
            <TuneIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="secondary"
            className={classes.actionIcon}
            title="削除"
            onClick={onDeleteHandler}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        </div>
      </Paper>
      <ColorSetting
        open={isColorOpen}
        src={props.photo.src}
        colorSetting={props.photo.colorSetting}
        onApply={onColorHandler}
        onClose={toggleColorOpen}
      />
    </div>
  )
}

export default React.memo(Photo)
