import React, { useEffect } from 'react'

import { AppState } from '../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { editActions } from '../redux/actions/editAction'

import { Button, Snackbar as SnackbarBase } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { REVISION_CHECK_INTERVAL } from '../utils/constant'
import { checkRevision } from '../utils/methods'

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const RevisionChecker: React.FC = () => {
  const dispatch = useDispatch()

  const isRevError = useSelector((state: AppState) => state.edit.isRevError)

  useEffect(() => {
    check()

    const id: NodeJS.Timeout = setInterval(
      () => check(id),
      REVISION_CHECK_INTERVAL
    )

    return () => clearInterval(id)
  }, [])

  const check = async (id?: NodeJS.Timeout): Promise<void> => {
    const isError = await checkRevision()

    if (isError) {
      dispatch(editActions.updateIsRevError(true))
      if (id) {
        clearInterval(id)
      }
    }
  }

  const onReload = (): void => {
    window.location.reload()
  }

  return (
    <SnackbarBase
      open={isRevError}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity="warning"
        action={
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={onReload}
          >
            リロード
          </Button>
        }
      >
        アプリが更新されています。画面をリロードしてください。
      </Alert>
    </SnackbarBase>
  )
}

export default RevisionChecker
