import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

import { AppState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { editActions } from '../../redux/actions/editAction'

import Dialog, { openDialog } from '../../components/Dialog'
import Header from './Header'
import Content from './Content'
import API from '../../utils/api'
import { DialogProps, ListProject } from '../../utils/Types'

const List: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  const isRevError = useSelector((state: AppState) => state.edit.isRevError)

  const [projects, setProjects] = useState<ListProject[]>([])
  const [userInfo, setUserInfo] = useState<Record<string, unknown> | undefined>(
    undefined
  )

  const [searchParams, setSearchParams] = useState<{
    updated: Date | null
    prjName: string
    grpName: string
  }>({
    updated: null,
    prjName: '',
    grpName: '',
  })
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  useEffect(() => {
    ;(async (): Promise<void> => {
      try {
        const token = await getAccessTokenSilently({
          audience: 'http://localhost:3001',
          scope: 'read:projects',
        })

        let isUserInfoSetted = false
        if (userInfo === undefined) {
          const userinfo = await axios.get(
            'https://mst-com.us.auth0.com/userinfo',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          const res = await API.post('user', userinfo.data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (res.data.result) {
            setUserInfo(userinfo.data)
            isUserInfoSetted = true
          } else {
            openDialog({
              type: 'userError',
              setDialogProps,
              onClickOk: closeDialog,
            })
            return
          }
        } else {
          isUserInfoSetted = true
        }

        if (isUserInfoSetted) {
          const res = await API.get('projects', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: searchParams,
          })

          setProjects(res.data.projects)
        }
      } catch (e) {
        console.log(e)
      }
    })()
  }, [userInfo, searchParams, getAccessTokenSilently])

  const onSearchHandler = React.useCallback(
    (updated: Date | null, prjName: string, grpName: string): void => {
      setSearchParams({
        updated,
        prjName,
        grpName,
      })
    },
    []
  )

  const movePage = (id?: string): void => {
    dispatch(editActions.resetStore())

    if (!id) {
      props.history.push('/Edit/new')
      return
    }

    const selectedProject = projects.find(
      (project) => project.id === Number(id)
    )

    if (selectedProject !== undefined) {
      props.history.push('/Edit/' + selectedProject.id)
    }
  }

  const confirmDeleteProject = (projectId: string): void => {
    openDialog({
      type: 'deleteProject',
      setDialogProps,
      onClickCancel: closeDialog,
      onClickOk: () => execDeleteProject(projectId),
    })
  }

  const execDeleteProject = async (projectId: string): Promise<void> => {
    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
      scope: 'delete:project',
    })

    const res = await API.delete('project', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { projectId },
    })

    if (res.data.result) {
      setSearchParams(Object.assign({}, searchParams))
    }

    closeDialog()
  }

  return (
    <div>
      <Header
        isActive={userInfo !== undefined && !isRevError}
        onCreateProject={movePage}
        onSearch={onSearchHandler}
      />
      <Content
        projects={isRevError ? [] : projects}
        movePage={movePage}
        onDelete={confirmDeleteProject}
      />
      <Dialog {...dialogProps} />
    </div>
  )
}

export default List
