import { createStore, combineReducers } from 'redux'
import EditReducer from './reducers/editReducer'
import EditState from './states/editState'

export type AppState = {
  edit: EditState
}

const store = createStore(
  combineReducers<AppState>({ edit: EditReducer })
)

export default store
