import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Button } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { Person as PersonIcon } from '@material-ui/icons'

import logo from '../assets/logo.png'

const useStyles = makeStyles(() => ({
  root: {
    height: '50px',
    background: grey[800],
    padding: '15px 15px 0 0',
  },
  logo: {
    marginTop: -15,
    height: '65px',
  },
  logout: {
    float: 'right',
  },
  name: {
    float: 'right',
    fontSize: 18,
    color: grey[300],
    marginRight: 20,
    marginTop: 5,
  },
}))

type Props = {
  name: string
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { logout } = useAuth0()

  const logoutHandler = (): void => {
    logout({ returnTo: window.location.origin + '/' })
  }

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />
      <Button
        variant="contained"
        color="secondary"
        className={classes.logout}
        startIcon={<PersonIcon />}
        onClick={logoutHandler}
      >
        ログアウト
      </Button>
      <span className={classes.name}>{props.name} 様</span>
    </div>
  )
}

export default Header
