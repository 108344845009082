import { nanoid } from 'nanoid'
import { hsvAdjustment } from '../utils/imageProcessing'
import { alphaBlend, asyncImageReader } from '../utils/methods'
import { ColorSetting, Photo as PhotoType, WatermarkInfo } from '../utils/Types'

export default class Photo implements PhotoType {
  id: string
  name: string
  src: string
  colorSetting: ColorSetting

  constructor(args: {
    name: string
    src: string
    id?: string
    colorSetting?: ColorSetting
  }) {
    if (args.id) {
      this.id = args.id
    } else {
      this.id = nanoid(10)
    }
    this.name = args.name
    this.src = args.src

    if (args.colorSetting) {
      this.colorSetting = args.colorSetting
    } else {
      this.colorSetting = {
        h: 50,
        s: 50,
        l: 50,
      }
    }
  }

  clone = (): Photo => {
    return new Photo(this)
  }

  getDataSource = async (watermarkInfo: WatermarkInfo): Promise<string> => {
    let dataSource = ''

    const image = await asyncImageReader(this.src)
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d')

    if (ctx) {
      ctx.drawImage(image, 0, 0)

      // 色調調整されている場合
      const cs = this.colorSetting
      if (!(cs.h === 50 && cs.s === 50 && cs.l === 50)) {
        const imgData = hsvAdjustment(
          ctx.getImageData(0, 0, canvas.width, canvas.height),
          cs
        )
        ctx.putImageData(imgData, 0, 0)
      }

      dataSource = canvas.toDataURL('image/jpeg')

      // ウォターマーク合成
      if (this.name === '001' && watermarkInfo.src !== '') {
        dataSource = await alphaBlend(dataSource, watermarkInfo)
      }

      dataSource = dataSource.split(',')[1]
    }

    return dataSource
  }

  getDataBlob = async (watermarkInfo: WatermarkInfo): Promise<Blob> => {
    const dataSource = await this.getDataSource(watermarkInfo)

    const bin = atob(dataSource)
    const tmp = this.src.match(/(:)([a-z/]+)(;)/)
    const mimeType = tmp !== null ? tmp[2] : ''

    const content = new Uint8Array(bin.length)
    for (let i = 0, l = bin.length; l > i; i++) {
      content[i] = bin.charCodeAt(i)
    }

    return new Blob([content], { type: mimeType })
  }
}
