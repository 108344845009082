import React, { ChangeEvent, useState } from 'react'

import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import { Button, Grid, TextField } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  Search as SearchIcon,
  NoteAdd as NoteAddIcon,
} from '@material-ui/icons/'

import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  })
)

const GreenButton = withStyles(() => ({
  root: {
    backgroundColor: green[300],
    '&:hover': {
      backgroundColor: '#5a8b5c',
    },
  },
}))(Button)

type Props = {
  isActive: boolean
  onCreateProject: (id?: string) => void
  onSearch: (updated: Date | null, prjName: string, grpName: string) => void
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [updated, setUpdated] = useState<Date | null>(null)
  const [prjName, setPrjName] = useState('')
  const [grpName, setGrpName] = useState('')

  const onPrjNameChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setPrjName(event.target.value.replace(/\s/g, ''))
  }

  const onGrpNameChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setGrpName(event.target.value.replace(/\s/g, ''))
  }

  const onClickHandler = (): void => {
    props.onCreateProject()
  }

  const onSearchClick = (): void => {
    props.onSearch(updated, prjName, grpName)
  }

  const onSearchAllClick = (): void => {
    setUpdated(null)
    setPrjName('')
    setGrpName('')

    props.onSearch(null, '', '')
  }

  return (
    <Grid container alignItems="center" spacing={3} className={classes.root}>
      <Grid item md={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClickHandler}
          startIcon={<NoteAddIcon />}
          disabled={!props.isActive}
        >
          新規プロジェクト
        </Button>
      </Grid>
      <Grid item md={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            label="更新日"
            value={updated}
            onChange={setUpdated}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={3}>
        <TextField
          label="プロジェクト名"
          variant="outlined"
          fullWidth
          value={prjName}
          onChange={onPrjNameChangeHandler}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          label="グループ名"
          variant="outlined"
          fullWidth
          value={grpName}
          onChange={onGrpNameChangeHandler}
        />
      </Grid>
      <Grid item md={1}>
        <GreenButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSearchClick}
          startIcon={<SearchIcon />}
          disabled={!props.isActive}
        >
          検索
        </GreenButton>
      </Grid>
      <Grid item md={1}>
        <Button
          variant="contained"
          color="default"
          fullWidth
          onClick={onSearchAllClick}
          disabled={!props.isActive}
        >
          全一覧
        </Button>
      </Grid>
    </Grid>
  )
}

export default Header
