import React, { useCallback, useEffect, useRef, useState } from 'react'

import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import {
  VerticalBarSeries,
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeriesPoint,
  RVNearestXData,
} from 'react-vis'

import { GraphData } from '../../utils/Types'

const GRAPH_HEIGHT = 430

const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  return windowDimensions
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: grey[300],
      textAlign: 'center',
      marginBottom: 15,
      fontWeight: 550,
    },
    content: {
      color: grey[400],
      textAlign: 'center',
    },
    crosshair: {
      color: 'white',
      backgroundColor: 'black',
      width: 20,
      opacity: 0.7,
      paddingLeft: 10,
    },
  })
)

type Props = {
  datas: GraphData[]
}

const Content: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const graphRef = useRef<HTMLDivElement>(null)

  const [tooltipVal, setTooltipVal] = useState<GraphData>({ x: '', y: 0 })
  const [tooltipPos, setTooltipPos] = useState({
    top: 0,
    left: 0,
  })
  const [yLabels, setYLabels] = useState<number[] | undefined>([0])

  useEffect(() => {
    props.datas.forEach((data, _index) => {
      if (data.y !== 0) {
        setYLabels(undefined)
        return false
      }
    })
  }, [props])

  const onMouseLeave = useCallback(() => {
    setTooltipVal({ x: '', y: 0 })
  }, [])

  const onNearestX = (
    datapoint: VerticalBarSeriesPoint,
    data: RVNearestXData<VerticalBarSeriesPoint>
  ) => {
    const label = '' + datapoint.x
    if (tooltipVal.x === '' || tooltipVal.x !== label) {
      setTooltipVal({ x: label, y: datapoint.y })
    }

    if (graphRef.current) {
      const { top: graphTop } = graphRef.current.getBoundingClientRect()

      let top = graphTop + GRAPH_HEIGHT * 0.5
      if (data.event.clientY < graphTop + GRAPH_HEIGHT * 0.6) {
        top += 50
      } else {
        top -= 150
      }

      setTooltipPos({
        top,
        left: Number(data.innerX) + 35,
      })
    }
  }

  const getDownloadCntObject = useCallback((): JSX.Element => {
    const date = new Date()
    const title = `${date.getFullYear()}年${
      date.getMonth() + 1
    }月のダウンロード枚数`

    const count = props.datas.length === 0 ? 0 : props.datas.slice(-1)[0].y
    const max = Number(process.env.REACT_APP_DOWNLOAD_LIMIT)
    const rest = count < max ? max - count : 0

    const content = `${count.toLocaleString()}/${max.toLocaleString()} 枚 (残 ${rest.toLocaleString()} 枚)`

    return (
      <div style={{ marginBottom: 50 }}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h5" className={classes.content}>
          {content}
        </Typography>
      </div>
    )
  }, [props.datas])

  return (
    <div>
      {getDownloadCntObject()}
      <Typography variant="h4" className={classes.title}>
        ダウンロード枚数履歴 (直近6か月)
      </Typography>
      <div ref={graphRef}>
        <XYPlot
          onMouseLeave={onMouseLeave}
          margin={{ left: 60 }}
          height={GRAPH_HEIGHT}
          width={useWindowDimensions().width - 40}
          style={{ background: grey[800] }}
          xType="ordinal"
        >
          <YAxis
            title="DL枚数"
            style={{
              line: { stroke: 'white' },
              ticks: { stroke: 'white' },
              text: { stroke: 'none', fill: 'white', fontWeight: 600 },
              title: { stroke: 'white', fontWeight: 100 },
            }}
            tickSizeOuter={0.1}
            tickValues={yLabels}
          />
          <XAxis
            style={{
              line: { stroke: 'white' },
              ticks: { stroke: 'white' },
              text: { stroke: 'none', fill: 'white', fontWeight: 600 },
            }}
          />
          <VerticalBarSeries
            data={props.datas}
            barWidth={0.5}
            onNearestX={onNearestX}
          />
        </XYPlot>
        {tooltipVal.x !== '' ? (
          <div
            className={classes.crosshair}
            style={{
              position: 'absolute',
              top: tooltipPos.top,
              left: tooltipPos.left,
              width: 70,
            }}
          >
            <p>{tooltipVal.x}</p>
            <p>{`${tooltipVal.y}枚`}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Content
