import React, { useState } from 'react'

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core'

const BARCODE_TYPE = [
  { name: 'CODE128', value: 'code_128_reader' },
  { name: 'CODE32', value: 'code_32_reader' },
  { name: 'CODE39', value: 'code_39_reader' },
  { name: 'CODE39 VIN', value: 'code_39_vin_reader' },
  { name: 'CODE93', value: 'code_93_reader' },
  { name: 'EAN', value: 'ean_reader' },
  { name: 'EAN-8', value: 'ean_8_reader' },
  { name: 'CODABAR', value: 'codabar_reader' },
  { name: 'UPC', value: 'upc_reader' },
  { name: 'UPCE', value: 'upc_e_reader' },
  { name: 'Interleaved 2 of 5', value: 'i2of5_reader' },
]

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '50px auto 0 auto',
      width: 400,
      padding: 10,
      '&:focus': {
        outline: 'none',
      },
    },
    select: {
      marginBottom: 10,
    },
    bottomGrid: {
      marginTop: 10,
    },
  })
)

type Props = {
  open: boolean
  onRead: (type: string) => void
  onClose: () => void
}

const BarcodeSelect: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const [selectedType, setSelectedType] = useState<string>(
    BARCODE_TYPE[0].value
  )

  const onChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const value = event.target.value as string

    setSelectedType(value)
  }

  const onReadClick = (): void => {
    props.onRead(selectedType)
  }

  const getSelectRenderValue = (value: unknown): string => {
    let result = ''

    const selectedType = BARCODE_TYPE.find((barcode) => barcode.value === value)
    if (selectedType) {
      result = selectedType.name
    }

    return result
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Paper className={classes.root}>
        <Grid
          container
          className={classes.select}
          justify="center"
          alignItems="center"
        >
          <Grid item sm={4}>
            <span>バーコード種別：</span>
          </Grid>
          <Grid item sm={6}>
            <Select
              value={selectedType}
              onChange={onChange}
              renderValue={getSelectRenderValue}
              fullWidth
            >
              {BARCODE_TYPE.map((barcode, index) => {
                return (
                  <MenuItem key={index} value={barcode.value}>
                    {barcode.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        <Divider />

        <Grid container justify="space-between" className={classes.bottomGrid}>
          <Grid item sm={4}>
            <Button
              color="default"
              variant="contained"
              fullWidth
              onClick={props.onClose}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={onReadClick}
            >
              読取開始
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default BarcodeSelect
