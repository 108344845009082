import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'

import {
  createStyles,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import {
  ErrorPage,
  Header,
  Loading,
  Notificator,
  ProtectedRoute,
  RevisionChecker,
} from './components/'
import { Edit, History, List } from './views/'

import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles(() =>
  createStyles({
    view: {
      padding: '15px',
    },
  })
)

const App: React.FC = () => {
  const classes = useStyles()
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
    },
  })

  const { isLoading, error, user, isAuthenticated } = useAuth0()

  if (error) {
    return <ErrorPage />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={theme}>
      {isAuthenticated ? (
        <>
          <Header name={user ? user.name : ''} />
          <Notificator />
          <RevisionChecker />
        </>
      ) : (
        <></>
      )}
      <div className={classes.view}>
        <Router>
          <Switch>
            {process.env.REACT_APP_MODE === '0' ? (
              <ProtectedRoute exact path="/" component={List} />
            ) : (
              <ProtectedRoute exact path="/" component={History} />
            )}

            <ProtectedRoute path="/Edit/:id" component={Edit} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
