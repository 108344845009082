import React from 'react'

import {
  Button,
  Dialog as DialogBase,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { DialogProps } from '../utils/Types'

const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <DialogBase
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onClickCancel !== undefined ? (
          <Button color="default" onClick={props.onClickCancel}>
            キャンセル
          </Button>
        ) : (
          ''
        )}
        {props.onClickOk !== undefined ? (
          <Button color={props.color} onClick={props.onClickOk}>
            OK
          </Button>
        ) : (
          ''
        )}
      </DialogActions>
    </DialogBase>
  )
}

export default Dialog

export const openDialog = ({
  type,
  msg,
  onClickOk,
  onClickCancel,
  setDialogProps,
}: {
  type: string
  msg?: React.ReactNode
  onClickOk?: () => void
  onClickCancel?: () => void
  setDialogProps: (props: DialogProps) => void
}): void => {
  const dialog: DialogProps = {
    open: true,
    title: 'エラー',
    content: '',
    color: 'secondary',
    onClickOk,
    onClickCancel,
  }

  switch (type) {
    case 'projectNotFound': // プロジェクトなし
      dialog.content = (
        <span>
          プロジェクトが見つかりませんでした。
          <br />
          一覧に戻ります。
        </span>
      )
      break
    case 'errorDownload': // ダウンロードエラー
      dialog.content = 'ダウンロード中にエラーが発生しました。'
      break
    case 'errorUpload': // アップロードエラー
      dialog.content = 'アップロード中にエラーが発生しました。'
      break
    case 'overPhotoCnt': // 読込上限オーバー
      dialog.content = `総読込枚数の最大値(${process.env.REACT_APP_READ_MAX}枚)を超えています。`
      break
    case 'groupNameDuplicated': // グループ名重複
      dialog.content = msg
      break
    case 'notLatestVer':
      dialog.content = msg
      break
    case 'photoSize': // 写真サイズ変更
      dialog.title = '確認'
      dialog.content = (
        <span>
          サイズを変更するとウォーターマークの設定が初期化されます。
          <br />
          よろしいですか?
        </span>
      )
      break
    case 'deleteAll': // 全画像の一括削除
      dialog.title = '確認'
      dialog.content = '本当にすべての画像を削除しますか？'
      break
    case 'deleteZero': // 000をすべて削除
      dialog.title = '確認'
      dialog.content = '本当に000の画像を削除しますか？'
      break
    case 'download': // ダウンロード
      dialog.title = '確認'
      dialog.content = (
        <span>
          ダウンロードを開始します。
          <br />
          よろしいですか？
        </span>
      )
      dialog.color = 'primary'
      break
    case 'upload': // アップロード
      dialog.title = '確認'
      dialog.content = (
        <span>
          アップロードを開始します。
          <br />
          よろしいですか？
        </span>
      )
      dialog.color = 'primary'
      break
    case 'userError':
      dialog.content = (
        <span>
          ユーザ情報の作成/取得に失敗しました。
          <br />
          画面を更新して再度お試しください。
        </span>
      )
      break
    case 'deleteProject':
      dialog.title = '確認'
      dialog.content = (
        <span>
          プロジェクトを削除します。
          <br />
          よろしいですか?
          <br />
          (※ 削除したプロジェクトは元に戻せません。)
        </span>
      )
      break
    default:
      dialog.open = false
  }

  setDialogProps(dialog)
}
