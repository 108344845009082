import React from 'react'

import { RndDragCallback, RndResizeCallback, Rnd } from 'react-rnd'

import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      outline: '1px dashed #c3c3c3',
    },
    watermark: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    transparentLayer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  })
)

type Props = {
  size: { width: number; height: number }
  position: { x: number; y: number }
  opacity: number
  onDragStop: RndDragCallback
  onResizeStop: RndResizeCallback
  src: string
}
const Watermark: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  if (props.src === '') {
    return <></>
  }

  return (
    <Rnd
      size={props.size}
      position={props.position}
      bounds="parent"
      onDragStop={props.onDragStop}
      onResizeStop={props.onResizeStop}
      lockAspectRatio={true}
      minWidth={20}
      minHeight={20}
    >
      <div className={classes.container}>
        <img
          style={{ opacity: props.opacity }}
          src={props.src}
          className={classes.watermark}
          alt="watermark"
        />
        <div className={classes.transparentLayer} />
      </div>
    </Rnd>
  )
}

export default React.memo(Watermark)
