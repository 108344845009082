import { ColorSetting } from './Types'

export const rgb2hsl = (r: number, g: number, b: number): number[] => {
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const diff = max - min
  const sum = max + min

  let h = 0
  switch (max) {
    case min:
      break
    case r:
      h = 60 * ((g - b) / diff)
      break
    case g:
      h = 60 * ((b - r) / diff) + 120
      break
    case b:
      h = 60 * ((r - g) / diff) + 240
      break
  }

  h = 0 <= h ? h : h + 360

  let s = 0
  if (sum / 2 < 128) {
    s = sum === 0 ? 0 : diff / sum
  } else {
    s = sum === 510 ? 0 : diff / (510 - sum)
  }
  s *= 100

  const l = ((sum / 2) * 100) / 255

  return [Math.floor(h), Math.floor(s), Math.floor(l)]
}

export const hsl2rgb = (h: number, s: number, l: number): number[] => {
  const tmpL = l < 50 ? l : 100 - l
  const max = 2.55 * (l + tmpL * (s / 100))
  const min = 2.55 * (l - tmpL * (s / 100))
  const diff = max - min

  const calc = (val: number): number => {
    return (val / 60) * diff + min
  }

  let r = 0
  let g = 0
  let b = 0
  if (0 <= h && h < 60) {
    r = max
    g = calc(h)
    b = min
  } else if (60 <= h && h < 120) {
    r = calc(120 - h)
    g = max
    b = min
  } else if (120 <= h && h < 180) {
    r = min
    g = max
    b = calc(h - 120)
  } else if (180 <= h && h < 240) {
    r = min
    g = ((240 - h) / 60) * diff + min
    b = max
  } else if (240 <= h && h < 300) {
    r = calc(h - 240)
    g = min
    b = max
  } else {
    r = max
    g = min
    b = calc(360 - h)
  }

  return [r, g, b]
}

export const hsvAdjustment = (
  imageData: ImageData,
  colorSetting: ColorSetting
): ImageData => {
  const result = new ImageData(
    new Uint8ClampedArray(imageData.data),
    imageData.width,
    imageData.height
  )

  for (let i = 0; i < imageData.data.length; i += 4) {
    let [r, g, b] = [
      imageData.data[i],
      imageData.data[i + 1],
      imageData.data[i + 2],
    ]

    let [h, s, l] = rgb2hsl(r, g, b)

    // if (i === 0) {
    //   console.log(`bef r:${r} g: ${g} b: ${b}`)
    //   console.log(`bef h:${h} s:${s} l:${l}`)
    // }

    h += Math.floor((colorSetting.h - 50) * 3.6)
    if (h < 0) {
      h += 360
    } else if (360 < h) {
      h -= 360
    }

    if (10 < Math.abs(r - g) || 10 < Math.abs(g - b) || 10 < Math.abs(b - r)) {
      const diffS = Math.abs(colorSetting.s - 50)
      if (colorSetting.s < 50) {
        s -= (s / 50) * diffS
      } else {
        s += ((100 - s) / 50) * diffS
      }
    } else {
      s = 0
    }

    const diffV = Math.abs(colorSetting.l - 50)
    if (colorSetting.l < 50) {
      l -= (l / 50) * diffV
    } else {
      l += ((100 - l) / 50) * diffV
    }

    ;[r, g, b] = hsl2rgb(Math.floor(h), Math.floor(s), Math.floor(l))

    result.data[i] = r
    result.data[i + 1] = g
    result.data[i + 2] = b

    // if (i === 0) {
    //   console.log(`aft h:${h} s:${s} l:${l}`)
    //   console.log(`aft r:${r} g:${g} b:${b}`)
    // }
  }

  return result
}
