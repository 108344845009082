import React from 'react'

import {
  createMuiTheme,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from '@material-ui/core'
import { Button, Paper } from '@material-ui/core/'
import { amber } from '@material-ui/core/colors'
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons'

import { ListProject } from '../../utils/Types'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: amber[500],
    },
  },
})

const formatDatetime = (datetime: string): string => {
  const dd = new Date(datetime)
  const YYYY = dd.getFullYear()
  const MM = ('00' + (dd.getMonth() + 1)).slice(-2)
  const DD = ('00' + dd.getDate()).slice(-2)
  const hh = ('00' + dd.getHours()).slice(-2)
  const mm = ('00' + dd.getMinutes()).slice(-2)
  const ss = ('00' + dd.getSeconds()).slice(-2)

  return `${YYYY}/${MM}/${DD} ${hh}:${mm}:${ss}`
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '40px',
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
  })
)

type Order = 'asc' | 'desc'
interface HeadCell {
  id: keyof ListProject
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  { id: 'updated', numeric: false, label: '更新日時' },
  { id: 'name', numeric: false, label: 'プロジェクト名' },
  { id: 'dispGroupName', numeric: false, label: 'グループ名' },
  { id: 'totalPhotoCnt', numeric: true, label: '総写真枚数' },
  { id: 'isWatermarkSetted', numeric: false, label: 'ウォーターマーク設定' },
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ListProject
  ) => void
  order: Order
  orderBy: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof ListProject>(
  order: Order,
  orderBy: Key
): (a: ListProject, b: ListProject) => number {
  return order === 'desc'
    ? (a, b): number => descendingComparator(a, b, orderBy)
    : (a, b): number => -descendingComparator(a, b, orderBy)
}

function stableSort<Project>(
  array: Project[],
  comparator: (a: Project, b: Project) => number
): Project[] {
  const stabilizedThis = array.map(
    (el, index) => [el, index] as [Project, number]
  )
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const EnhancedTableHead = (props: EnhancedTableProps): JSX.Element => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof ListProject) => (
    event: React.MouseEvent<unknown>
  ): void => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

type Props = {
  projects: ListProject[]
  movePage: (id: string) => void
  onDelete: (id: string) => void
}

const Content: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof ListProject>('updated')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ListProject
  ): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onEditHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (event.currentTarget.dataset.projectId) {
      props.movePage(event.currentTarget.dataset.projectId)
    }
  }

  const onDeleteHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (event.currentTarget.dataset.projectId) {
      props.onDelete(event.currentTarget.dataset.projectId)
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(props.projects, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, _index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell component="th" scope="row">
                        {formatDatetime(row.updated)}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.dispGroupName}</TableCell>
                      <TableCell>{row.totalPhotoCnt}</TableCell>
                      <TableCell>
                        {row.isWatermarkSetted ? (
                          <RadioButtonUncheckedIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <ThemeProvider theme={theme}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                data-project-id={row.id}
                                onClick={onEditHandler}
                                startIcon={<EditIcon />}
                              >
                                編集
                              </Button>
                            </ThemeProvider>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant="contained"
                              color="secondary"
                              data-project-id={row.id}
                              onClick={onDeleteHandler}
                              startIcon={<DeleteIcon />}
                              fullWidth
                            >
                              削除
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="1ページ当たり"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.projects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default Content
