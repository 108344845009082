import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  createStyles,
  Divider,
  Grid,
  Input,
  makeStyles,
  Modal,
  Paper,
  Slider,
  Typography,
} from '@material-ui/core'
import {
  Brightness5 as Brightness5Icon,
  Colorize as ColorizeIcon,
  Spa as SpaIcon,
} from '@material-ui/icons'

import { PREVIEW_SIZE } from '../utils/constant'
import { ColorSetting as ColorSettingType } from '../utils/Types'
import { hsvAdjustment } from '../utils/imageProcessing'
import { asyncImageReader } from '../utils/methods'

const MODAL_SIZE = 350

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '50px auto 0 auto',
      width: PREVIEW_SIZE,
      padding: 10,
      '&:focus': {
        outline: 'none',
      },
    },
    canvas: {
      display: 'block',
      margin: '0 auto 10px auto',
    },
    opacityText: {
      padding: '7px 0 7px 7px',
    },
    previewImage: {
      display: 'inline-block',
      maxWidth: MODAL_SIZE,
      maxHeight: MODAL_SIZE,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    previewImageCover: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    bottomGrid: {
      marginTop: 10,
    },
    inputFileHide: {
      opacity: '0',
      appearance: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    title: {
      marginTop: 10,
    },
    input: {
      width: 42,
    },
    sliders: {
      marginBottom: 10,
    },
  })
)

type Props = {
  open: boolean
  src: string
  colorSetting: ColorSettingType
  onApply: (hsvSetting: ColorSettingType) => void
  onClose: () => void
}

const ColorSetting: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const canvasElement = useRef<HTMLCanvasElement>()
  const setCanvasElement = React.useCallback((node: HTMLCanvasElement) => {
    canvasElement.current = node
    if (node) {
      setIsCanvas(true)
    } else {
      setIsCanvas(false)
    }
  }, [])

  const [hue, setHue] = useState(props.colorSetting.h)
  const [saturation, setSaturation] = useState(props.colorSetting.s)
  const [lightness, setLightness] = useState(props.colorSetting.l)
  const [origImageData, setOrigImageData] = useState<ImageData>()
  const [isSetCanvas, setIsCanvas] = useState(false)

  useEffect(() => {
    setHue(props.colorSetting.h)
    setSaturation(props.colorSetting.s)
    setLightness(props.colorSetting.l)
  }, [props.colorSetting])

  useEffect(() => {
    if (!isSetCanvas) {
      return
    }

    ;(async () => {
      if (!canvasElement.current) {
        return
      }

      const ctx = canvasElement.current.getContext('2d')
      if (ctx === null) {
        return
      }

      const image = await asyncImageReader(props.src)
      let width = MODAL_SIZE
      let height = MODAL_SIZE

      if (image.width < image.height) {
        width *= image.width / image.height
      } else {
        height *= image.height / image.width
      }

      canvasElement.current.width = width
      canvasElement.current.height = height

      ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height)

      setOrigImageData(ctx.getImageData(0, 0, width, height))
    })()
  }, [props.src, canvasElement.current, isSetCanvas])

  useEffect(() => {
    if (!canvasElement.current) {
      return
    }
    const ctx = canvasElement.current.getContext('2d')
    if (ctx === null) {
      return
    }

    if (!origImageData) {
      return
    }

    let newImageData = origImageData
    if (!(hue === 50 && saturation === 50 && lightness === 50)) {
      newImageData = hsvAdjustment(origImageData, {
        h: hue,
        s: saturation,
        l: lightness,
      })
    }

    ctx.putImageData(newImageData, 0, 0)
  }, [hue, saturation, lightness, canvasElement, origImageData])

  const changeSliderHue = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: number | number[]
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value
    setHue(newValue)
  }

  const changeSliderSaturation = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: number | number[]
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value
    setSaturation(newValue)
  }

  const changeSliderLightness = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: number | number[]
  ) => {
    const newValue = Array.isArray(value) ? value[0] : value
    setLightness(newValue)
  }

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parent = event.target.parentNode as HTMLElement

    const newValue = event.target.value === '' ? 0 : Number(event.target.value)
    switch (parent.dataset.hsv) {
      case 'hue':
        setHue(newValue)
        break
      case 'saturation':
        setSaturation(newValue)
        break
      case 'lightness':
        setLightness(newValue)
        break
    }
  }

  const blurInput = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const parent = event.target.parentNode as HTMLElement

    let setMethod: (value: React.SetStateAction<number>) => void
    let value = -1
    switch (parent.dataset.hsv) {
      case 'hue':
        setMethod = setHue
        value = hue
        break
      case 'saturation':
        setMethod = setSaturation
        value = saturation
        break
      case 'lightness':
        setMethod = setLightness
        value = lightness
        break
      default:
        return
    }

    if (value < 0) {
      setMethod(0)
    } else if (100 < value) {
      setMethod(100)
    }
  }

  const cancel = (): void => {
    setHue(props.colorSetting.h)
    setSaturation(props.colorSetting.s)
    setLightness(props.colorSetting.l)

    props.onClose()
  }

  const resetSetting = (): void => {
    setHue(50)
    setSaturation(50)
    setLightness(50)
  }

  const apply = (): void => {
    props.onApply({ h: hue, s: saturation, l: lightness })
  }

  return (
    <Modal
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Paper className={classes.root}>
        <div>
          <canvas
            className={classes.canvas}
            ref={setCanvasElement}
            data-name="canvas"
          />
        </div>
        <div className={classes.sliders}>
          <Typography id="hue-slider" gutterBottom className={classes.title}>
            色味 (Hue)
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <ColorizeIcon />
            </Grid>
            <Grid item xs>
              <Slider
                value={hue}
                onChange={changeSliderHue}
                aria-labelledby="hue-slider"
                data-hsv="hue"
              />
            </Grid>
            <Grid item>
              <Input
                className={classes.input}
                value={hue}
                onChange={changeInput}
                onBlur={blurInput}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                }}
                data-hsv="hue"
              />
            </Grid>
          </Grid>
          <Typography
            id="saturation-slider"
            gutterBottom
            className={classes.title}
          >
            鮮やかさ (Saturation)
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SpaIcon />
            </Grid>
            <Grid item xs>
              <Slider
                value={saturation}
                onChange={changeSliderSaturation}
                aria-labelledby="saturation-slider"
                data-hsv="saturation"
              />
            </Grid>
            <Grid item>
              <Input
                className={classes.input}
                value={saturation}
                onChange={changeInput}
                onBlur={blurInput}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                }}
                data-hsv="saturation"
              />
            </Grid>
          </Grid>
          <Typography
            id="lightness-slider"
            gutterBottom
            className={classes.title}
          >
            明るさ (Lightness)
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Brightness5Icon />
            </Grid>
            <Grid item xs>
              <Slider
                value={lightness}
                onChange={changeSliderLightness}
                aria-labelledby="lightness-slider"
              />
            </Grid>
            <Grid item>
              <Input
                className={classes.input}
                value={lightness}
                onChange={changeInput}
                onBlur={blurInput}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                }}
                data-hsv="lightness"
              />
            </Grid>
          </Grid>
        </div>

        <Divider />

        <Grid container justify="space-between" className={classes.bottomGrid}>
          <Grid item sm={3}>
            <Button
              color="default"
              variant="contained"
              fullWidth
              onClick={cancel}
            >
              キャンセル
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={resetSetting}
              disabled={hue === 50 && saturation === 50 && lightness === 50}
              title="すべてを初期値(50)に戻します"
            >
              初期値に戻す
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={apply}
              disabled={
                props.colorSetting.h === hue &&
                props.colorSetting.s === saturation &&
                props.colorSetting.l === lightness
              }
            >
              適用
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default ColorSetting
