import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Button, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      color: 'white',
      display: 'table',
      textAlign: 'center',
      '& div': {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: 40,
      },
    },
  })
)

const ErrorPage: React.FC = () => {
  const classes = useStyles()
  const { logout } = useAuth0()

  const onBack = (): void => {
    logout({ returnTo: window.location.origin + '/' })
  }

  return (
    <div className={classes.root}>
      <div>
        <span>現在このアカウントはログインできません。</span>
        <br />
        <Button color="default" variant="contained" onClick={onBack}>
          ログイン画面へ
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
