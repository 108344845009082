import React, { useEffect, useState } from 'react'
import Quagga, { QuaggaJSConfigObject } from '@ericblade/quagga2'
import { nanoid } from 'nanoid'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import { editActions } from '../../redux/actions/editAction'

import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
import {
  GetApp as GetAppIcon,
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons'
import { createStyles, withStyles, makeStyles } from '@material-ui/core/styles'

import { Dialog, ModalWithLinearProgress } from '../../components/'
import { openDialog } from '../../components/Dialog'

import { Group } from '../../classes/'
import { IMAGE_SIZE_MAX, IMAGE_SIZE_MIN } from '../../utils/constant'
import { paddingLeft } from '../../utils/methods'
import { DialogProps, PhotoCount, PhotoSize } from '../../utils/Types'
import FtpUpload from './FtpUpload'
import BarcodeSelect from './BarcodeSelect'

const imageUrlToBase64 = async (url: string): Promise<string> => {
  let base64 = ''

  const result = await axios.get(url, {
    responseType: 'arraybuffer',
  })

  switch (url.split('?')[0].slice(-3)) {
    case 'jpg':
      base64 = 'data:image/jpeg;base64,'
      break
    case 'png':
      base64 = 'data:image/png;base64,'
      break
    default:
      return ''
  }

  base64 += btoa(
    new Uint8Array(result.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )

  return base64
}

const imageSizeAdjust = (width: number, height: number): number[] => {
  // ヨコ
  let newWidth = width
  if (newWidth < IMAGE_SIZE_MIN) {
    newWidth = IMAGE_SIZE_MIN
  } else if (IMAGE_SIZE_MAX < newWidth) {
    newWidth = IMAGE_SIZE_MAX
  }

  // タテ
  let newHeight = height
  if (newHeight < IMAGE_SIZE_MIN) {
    newHeight = IMAGE_SIZE_MIN
  } else if (IMAGE_SIZE_MAX < newHeight) {
    newHeight = IMAGE_SIZE_MAX
  }

  return [newWidth, newHeight]
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingBottom: '10px',
      background: 'black',
      borderBottom: '1px solid #c3c3c3',
      textAlign: 'center',
      marginBottom: '10px',
    },
    spanText: {
      color: 'white',
      display: 'block',
      paddingTop: '7px',
      fontSize: '14px',
    },
    textfield: {
      '& input': {
        padding: '8.5px 14px',
      },
    },
    inputFileHide: {
      opacity: '0',
      appearance: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    select: {
      fontSize: 15,
    },
  })
)

const TealButton = withStyles(() => ({
  root: {
    backgroundColor: teal[200],
    '&:hover': {
      backgroundColor: '#5B8E89',
    },
  },
}))(Button)

const quaggaConfig: QuaggaJSConfigObject = {
  inputStream: {
    singleChannel: false,
  },
  locator: {
    patchSize: 'medium',
    halfSample: true,
  },
  decoder: {
    readers: [
      {
        format: 'code_128_reader',
        config: { supplements: [] },
      },
    ],
  },
  locate: true,
  src: undefined,
}

const QuaggaPatchSize = ['large', 'medium', 'small', 'x-small', 'x-large']

enum SizeType {
  NetShop1,
  NetShop2,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Free = 9,
}

const SIZE_LIST = [
  {
    id: SizeType.NetShop1,
    name: 'ネットショップ1(1000×1000)',
    nameSh: 'ネットショップ1',
    size: {
      width: 1000,
      height: 1000,
    } as PhotoSize,
  },
  {
    id: SizeType.NetShop2,
    name: 'ネットショップ2(600×600)',
    nameSh: 'ネットショップ2',
    size: {
      width: 600,
      height: 600,
    },
  },
  {
    id: SizeType.Facebook,
    name: 'Facebook(1200×630)',
    nameSh: 'Facebook',
    size: {
      width: 1200,
      height: 630,
    },
  },
  {
    id: SizeType.Twitter,
    name: 'Twitter(800×418)',
    nameSh: 'Twitter',
    size: {
      width: 800,
      height: 418,
    },
  },
  {
    id: SizeType.Instagram,
    name: 'Instagram(1080×1080)',
    nameSh: 'Instagram',
    size: {
      width: 1080,
      height: 1080,
    },
  },
  {
    id: SizeType.YouTube,
    name: 'YouTube(1280×720)',
    nameSh: 'YouTube',
    size: {
      width: 1280,
      height: 720,
    },
  },
  {
    id: SizeType.Free,
    name: '自由サイズ入力',
    nameSh: '自由サイズ入力',
    size: {
      width: 0,
      height: 0,
    },
  },
]

interface Props {
  photoCount: PhotoCount
  checkGroupNameDup: () => boolean
  onPhotoCountChange: (photoCount: PhotoCount) => void
  onDeleteAllPhotos: () => void
  onHsvClick: () => void
  onAddPhotos: (photos: File[]) => void
  onWatermarkClick: () => void
  onBackToList: () => void
  onDownload: () => void
  onUpload: () => void
  onFtpUpload: (
    host: string,
    port: string,
    user: string,
    password: string,
    dir: string
  ) => Promise<{ code: number; msg: string }>
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const groups = useSelector((state: AppState) => state.edit.groups)
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const projectName = useSelector((state: AppState) => state.edit.projectName)
  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )
  const ftpSetting = useSelector((state: AppState) => state.edit.ftpSetting)

  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })
  const [photoWidth, setPhotoWidth] = useState(photoSize.width)
  const [photoHeight, setPhotoHeight] = useState(photoSize.height)
  const [isReadingBarcode, setIsReadingBarcode] = useState(false)
  const [readProggress, setReadProggress] = useState(0)
  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false)
  const [isFtpOpen, setIsFtpOpen] = useState(false)
  const [sizeType, setSizeType] = useState<SizeType>(SizeType.NetShop1)

  useEffect(() => {
    setPhotoWidth(photoSize.width)
    setPhotoHeight(photoSize.height)

    const newSize = SIZE_LIST.find(
      (size) =>
        size.size.width === photoSize.width &&
        size.size.height === photoSize.height
    )

    const newSizeType = newSize?.id

    if (newSizeType !== undefined) {
      setSizeType(newSizeType)
    } else {
      setSizeType(9)
    }
  }, [photoSize])

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const toggleWatermarkOpen = (): void => {
    const photo = groups[0].photos.find((photo) => photo.name !== '000')
    if (photo && photo.src) {
      props.onWatermarkClick()
    }
  }

  const toggleFtpOpen = (): void => {
    setIsFtpOpen(!isFtpOpen)
  }

  const onSizeTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let text = event.target.value.replace(new RegExp(/^0+/g), '')
    if (text === '') {
      text = '0'
    }

    // 先頭0以外 かつ 数字のみ
    const pattern = /^([1-9]\d*|0)$/
    if (!pattern.test(text)) {
      return
    }

    const elemId = event.target.id
    if (elemId === 'width') {
      setPhotoWidth(parseInt(text))
    } else {
      setPhotoHeight(parseInt(text))
    }

    setSizeType(SizeType.Free)
  }

  const onSizeTextBlur = (): void => {
    // サイズのタテヨコ制限チェック
    const [newWidth, newHeight] = imageSizeAdjust(photoWidth, photoHeight)

    if (
      watermarkInfo.src !== '' &&
      (newWidth !== photoSize.width || newHeight !== photoSize.height)
    ) {
      openDialog({
        type: 'photoSize',
        setDialogProps,
        onClickCancel: cancelPhotoSizeChange,
        onClickOk: () => execPhotoSizeChange(newWidth, newHeight),
      })
      return
    }

    execPhotoSizeChange(newWidth, newHeight)
  }

  const onSizeSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as SizeType

    const newSizeType = SIZE_LIST.find((size) => size.id === value)
    if (!newSizeType) {
      return
    }

    if (
      watermarkInfo.src !== '' &&
      value !== SizeType.Free &&
      (newSizeType.size.width !== photoSize.width ||
        newSizeType.size.height !== photoSize.height)
    ) {
      openDialog({
        type: 'photoSize',
        setDialogProps,
        onClickCancel: cancelPhotoSizeChange,
        onClickOk: () =>
          execPhotoSizeChange(
            newSizeType.size.width,
            newSizeType.size.height,
            value
          ),
      })
      return
    }

    setSizeType(value)
    if (value !== SizeType.Free) {
      execPhotoSizeChange(newSizeType.size.width, newSizeType.size.height)
    }
  }

  const cancelPhotoSizeChange = React.useCallback((): void => {
    setPhotoWidth(photoSize.width)
    setPhotoHeight(photoSize.height)

    closeDialog()
  }, [photoSize, closeDialog])

  const execPhotoSizeChange = (
    width: number,
    height: number,
    newSizeType?: SizeType
  ): void => {
    closeDialog()

    dispatch(
      editActions.updatePhotoSize({
        width: width,
        height: height,
      })
    )

    if (newSizeType) {
      setSizeType(newSizeType)
    }

    dispatch(editActions.clearWatermarkInfo())
  }

  const execDeleteAll = (): void => {
    props.onDeleteAllPhotos()
    closeDialog()
  }

  const execDeleteZero = (): void => {
    const newGroups = groups.map((group) => {
      if (!group.isHeadBarcode) {
        return group
      }

      const newGroup = group.clone()
      newGroup.photos.shift()
      newGroup.isHeadBarcode = false

      return newGroup
    })

    dispatch(editActions.updateGroups(newGroups))

    closeDialog()
  }

  const execDownload = (): void => {
    props.onDownload()
    closeDialog()
  }

  const execUpload = (): void => {
    props.onUpload()
    closeDialog()
  }

  const execFtpUpload = async (
    host: string,
    port: string,
    user: string,
    password: string,
    dir: string
  ): Promise<{
    code: number
    msg: string
  }> => {
    return await props.onFtpUpload(host, port, user, password, dir)
  }

  const fileSelectHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const fileList = event.currentTarget.files

    if (fileList === null) {
      return
    }

    const files: File[] = []
    Array.from(fileList).forEach((file) => {
      files.push(file)
    })

    props.onAddPhotos(files)
  }

  const projectNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(
      editActions.updateProjectName(
        event.target.value.replace(/[¥¥/:?*"<>|]/g, '')
      )
    )
  }

  const readBarcode = async (type: string): Promise<void> => {
    setIsBarcodeOpen(false)
    setReadProggress(0)
    setIsReadingBarcode(true)

    let totalPhotoCnt = 0
    groups.forEach((group) => {
      totalPhotoCnt += group.photos.length
    })

    if (quaggaConfig.inputStream) {
      quaggaConfig.inputStream.constraints = {
        width: photoWidth,
        height: photoHeight,
      }
    }
    if (quaggaConfig.decoder && quaggaConfig.decoder.readers) {
      quaggaConfig.decoder.readers = [
        {
          format: type,
          config: { supplements: [] },
        },
      ]
    }

    let readPhotoCnt = 0
    let readBarcodeCnt = 0
    const newGroups: Group[] = []
    for (const group of groups) {
      const photos = [...group.photos]

      let newGroup = group.clone()
      newGroup.photos = []
      for (const photo of photos) {
        let isFoundBarcode = false
        for (const patchSize of QuaggaPatchSize) {
          let src = photo.src
          if (photo.src.substr(0, 4) === 'http') {
            src = await imageUrlToBase64(photo.src)
          }

          if (src === '') {
            readPhotoCnt++
            setReadProggress((readPhotoCnt * 100) / totalPhotoCnt)
            continue
          }

          if (quaggaConfig.locator) {
            quaggaConfig.locator.patchSize = patchSize
          }
          quaggaConfig.src = src
          const result = await Quagga.decodeSingle(quaggaConfig)

          if (result?.codeResult && result.codeResult.code) {
            if (newGroup.photos.length !== 0) {
              newGroups.push(newGroup)
            }

            photo.name = '000'

            newGroup = new Group({
              id: nanoid(20),
              name: result.codeResult.code,
              photos: [photo],
              photoCnt: 1,
              isHeadBarcode: true,
              isError: false,
            })

            readBarcodeCnt++

            isFoundBarcode = true

            break
          }
        }

        if (!isFoundBarcode) {
          const offset = newGroup.isHeadBarcode ? 0 : 1
          photo.name = paddingLeft(newGroup.photos.length + offset, '0', 3)
          newGroup.photos.push(photo)
        }

        readPhotoCnt++
        setReadProggress((readPhotoCnt * 100) / totalPhotoCnt)
      }

      newGroups.push(newGroup)
    }

    const newPhotoCount = Object.assign({}, props.photoCount)
    newPhotoCount.barcode += readBarcodeCnt
    props.onPhotoCountChange(newPhotoCount)

    setTimeout(() => {
      dispatch(editActions.updateGroups(newGroups))

      setIsReadingBarcode(false)
    }, 1000)
  }

  const getDisplayPhotoCnt = (): string => {
    let result = `元画像数 ${props.photoCount.photo}`
    result += ' / '
    result += `読取バーコード ${props.photoCount.barcode}`

    if (process.env.REACT_APP_MODE === '0') {
      result += ' / '
      result += `保存数 ${props.photoCount.uploaded}`
    }

    return result
  }

  const clickFtpUpload = (): void => {
    if (!props.checkGroupNameDup()) {
      return
    }

    toggleFtpOpen()
  }

  const getSelectRenderValue = (value: unknown): string => {
    let result = ''

    const selectedSizeType = SIZE_LIST.find((size) => size.id === value)
    if (selectedSizeType) {
      result = selectedSizeType.nameSh
    }

    return result
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item md={1}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            onClick={props.onBackToList}
          >
            戻る
          </Button>
        </Grid>
        <Grid item md={3}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container justify="space-between" spacing={1}>
                <Grid item md={3}>
                  <TextField
                    id="width"
                    label="横"
                    variant="outlined"
                    size="small"
                    value={photoWidth}
                    onChange={onSizeTextChange}
                    onBlur={onSizeTextBlur}
                    className={classes.textfield}
                    title={`最大${IMAGE_SIZE_MAX}まで`}
                    disabled={groups.length !== 0}
                  />
                </Grid>
                <Grid item md={1}>
                  <span className={classes.spanText}>×</span>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    id="height"
                    label="縦"
                    variant="outlined"
                    size="small"
                    value={photoHeight}
                    onChange={onSizeTextChange}
                    onBlur={onSizeTextBlur}
                    className={classes.textfield}
                    title={`最大${IMAGE_SIZE_MAX}まで`}
                    disabled={groups.length !== 0}
                  />
                </Grid>
                <Grid item md={5}>
                  <Select
                    value={sizeType}
                    onChange={onSizeSelectChange}
                    renderValue={getSelectRenderValue}
                    className={classes.select}
                    fullWidth
                    disabled={groups.length !== 0}
                  >
                    {SIZE_LIST.map((size) => {
                      return (
                        <MenuItem key={size.id} value={size.id}>
                          {size.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() =>
                  openDialog({
                    type: 'deleteAll',
                    setDialogProps,
                    onClickOk: execDeleteAll,
                    onClickCancel: closeDialog,
                  })
                }
                disabled={groups.length === 0}
              >
                全画像の削除
              </Button>
            </Grid>
            <Grid item md={6}>
              <TealButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={props.onHsvClick}
                disabled={groups.length === 0}
              >
                一括色調補正
              </TealButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TealButton variant="contained" fullWidth>
                    ファイル選択
                    <input
                      type="file"
                      className={classes.inputFileHide}
                      accept="image/jpeg,image/png,image/gif"
                      multiple
                      onChange={fileSelectHandler}
                    />
                  </TealButton>
                </Grid>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={() => setIsBarcodeOpen(true)}
                    disabled={groups.length === 0}
                  >
                    ﾊﾞｰｺｰﾄﾞ自動読取
                  </TealButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      openDialog({
                        type: 'deleteZero',
                        setDialogProps,
                        onClickOk: execDeleteZero,
                        onClickCancel: closeDialog,
                      })
                    }
                    disabled={groups.length === 0}
                  >
                    000をすべて削除
                  </TealButton>
                </Grid>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={toggleWatermarkOpen}
                    disabled={groups.length === 0}
                  >
                    ｳｫｰﾀｰﾏｰｸ自動合成
                  </TealButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item md={10}>
              <TextField
                label="プロジェクト名"
                variant="outlined"
                size="small"
                fullWidth
                className={classes.textfield}
                value={projectName}
                onChange={projectNameChangeHandler}
              />
            </Grid>
            <Grid item md={10}>
              <span className={classes.spanText}>{getDisplayPhotoCnt()}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Grid container direction="column" spacing={1}>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<GetAppIcon />}
                onClick={() =>
                  openDialog({
                    type: 'download',
                    setDialogProps,
                    onClickOk: execDownload,
                    onClickCancel: closeDialog,
                  })
                }
                disabled={groups.length === 0 || projectName === ''}
              >
                ダウンロード
              </Button>
            </Grid>
            {process.env.REACT_APP_MODE === '0' ? (
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  onClick={() =>
                    openDialog({
                      type: 'upload',
                      setDialogProps,
                      onClickOk: execUpload,
                      onClickCancel: closeDialog,
                    })
                  }
                  disabled={groups.length === 0 || projectName === ''}
                >
                  サーバに保存
                </Button>
              </Grid>
            ) : (
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  onClick={clickFtpUpload}
                  disabled={groups.length === 0 || projectName === ''}
                >
                  FTPサーバに保存
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <BarcodeSelect
        open={isBarcodeOpen}
        onRead={readBarcode}
        onClose={() => {
          setIsBarcodeOpen(false)
        }}
      />
      <FtpUpload
        open={isFtpOpen}
        ftpSetting={ftpSetting}
        onClose={toggleFtpOpen}
        onUpload={execFtpUpload}
      />
      <Dialog {...dialogProps} />
      <ModalWithLinearProgress
        open={isReadingBarcode}
        progress={readProggress}
        message="読み取り中..."
        color="primary"
      />
    </div>
  )
}

export default React.memo(Header)
