import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { blue, grey } from '@material-ui/core/colors'
import { Collapse } from '@material-ui/core'

import { NOTIFICATION_CHECK_INTERVAL } from '../utils/constant'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      padding: '5px 10px',
      textAlign: 'center',
      color: 'white',
      fontSize: 14,
      background: blue[700],
      '& a': {
        color: 'white',
      },
    },
    maintenance: {
      color: grey[900],
      background: '#ffa000',
      '& a': {
        color: grey[900],
      },
    },
    hide: {
      display: 'none',
    },
    close: {
      padding: 0,
      float: 'right',
    },
  })
)

function useValueRef<T>(val: T) {
  const ref = useRef(val)
  React.useEffect(() => {
    ref.current = val
  }, [val])
  return ref
}

type Notification = {
  type: string
  title: string
  message: string
  from: string
  to: string
  url: string
}

const initialNotification: Notification = {
  type: '0',
  title: '',
  message: '',
  from: '2020/01/01 00:00',
  to: '2020/01/01 00:00',
  url: '',
}

const Notificator: React.FC = () => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState<Notification>(
    initialNotification
  )

  const notificationRef = useValueRef(notification)

  const checkNotification = useCallback((target: Notification): boolean => {
    const to = new Date(target.to)
    const from = new Date(target.from)

    if (
      target.type === '' ||
      target.title === '' ||
      Number.isNaN(from.getTime()) ||
      Number.isNaN(to.getTime()) ||
      target.message === ''
    ) {
      return false
    }
    return true
  }, [])

  const getNotification = async () => {
    try {
      const now = Date.now()
      let toDate = new Date(notificationRef.current.to)

      // メンテナンス通知 かつ 終了前の場合
      if (notificationRef.current.type === '0' && now <= toDate.getTime()) {
        return
      }

      const data = (await axios.get(`/notification.json?${now}`))
        .data as Notification

      if (!checkNotification(data)) {
        setOpen(false)
        return
      }

      setNotification(data)

      toDate = new Date(data.to)

      if (toDate.getTime() < now - 1 * 60 * 1000) {
        setOpen(false)
        return
      }

      if (data.type === '0') {
        setOpen(true)
      } else {
        const fromDate = new Date(data.from)

        if (fromDate.getTime() <= now) {
          setOpen(true)
        } else {
          setOpen(false)
        }
      }
    } catch (e) {
      setNotification(initialNotification)
      console.log(e)
    }
  }

  useEffect(() => {
    getNotification()

    const id = setInterval(() => getNotification(), NOTIFICATION_CHECK_INTERVAL)

    return () => clearInterval(id)
  }, [])

  const getNotificationStr = useCallback((): string => {
    let result = ''

    if (!checkNotification(notification)) {
      return result
    }

    if (notification.type === '0') {
      // メンテナンス通知
      result = `${notification.title} : ${notification.from} ～ ${notification.to} ${notification.message}`
    } else {
      // その他通知
      result = `${notification.title} : ${notification.message}`
    }

    return result
  }, [notification])

  return (
    <Collapse in={open}>
      <div
        className={
          classes.root +
          ' ' +
          (notification.type === '0' ? classes.maintenance : '')
        }
      >
        <span>{getNotificationStr()}</span>
        {notification.url !== '' ? (
          <a href={notification.url} target="_blank" rel="noreferrer">
            詳しくはこちら
          </a>
        ) : (
          <></>
        )}
      </div>
    </Collapse>
  )
}

export default Notificator
