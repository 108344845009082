import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import crypto from 'crypto'

import { AppState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { editActions } from '../../redux/actions/editAction'

import Header from './Header'
import Content from './Content'
import Dropzone from './Dropzone'
import WatermarkSetting from './WatermarkSetting'
import { ColorSetting, ModalWithLinearProgress } from '../../components/'
import { Group } from '../../classes/'

import {
  ColorSetting as ColorSettingType,
  DialogProps,
  ModalProps,
  PhotoCount,
  WatermarkInfo,
} from '../../utils/Types'
import {
  checkRevision,
  paddingLeft,
  readImageFile,
  createUploadData,
} from '../../utils/methods'
import Modal from '../../components/Modal'
import API from '../../utils/api'
import history from '../../utils/history'
import Dialog, { openDialog } from '../../components/Dialog'

const decryptFtpPassword = (password: string, iv: string): string => {
  let result = ''

  if (password && process.env.REACT_APP_CRYPTO_KEY) {
    const decipher = crypto.createDecipheriv(
      'aes-256-cbc',
      Buffer.from(process.env.REACT_APP_CRYPTO_KEY, 'base64'),
      Buffer.from(iv, 'binary')
    )
    const decrypted = decipher.update(password, 'hex', 'utf8')
    result = decrypted + decipher.final()
  }

  return result
}

type Props = RouteComponentProps<{ id: string }>

const Edit: React.FC<Props> = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()
  const groups = useSelector((state: AppState) => state.edit.groups)
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )
  const isRevError = useSelector((state: AppState) => state.edit.isRevError)
  const isUpdated = useSelector((state: AppState) => state.edit.isUpdated)

  const [projectId, setProjectId] = useState(props.match.params.id)
  const projectName = useSelector((state: AppState) => state.edit.projectName)
  const [isReading, setIsReading] = useState(false)
  const [readProggress, setReadProggress] = useState(0)
  const [isWatermarkOpen, setIsWatermarkOpen] = useState(false)
  const [isColorOpen, setIsColorOpen] = useState(false)

  const [photoCount, setPhotoCount] = useState<PhotoCount>({
    photo: 0,
    barcode: 0,
    uploaded: 0,
  })
  const [modalProps, setModalProps] = useState<ModalProps>({
    open: false,
    message: '',
    color: 'primary',
  })
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })
  const [colorSetting, setColorSetting] = useState<ColorSettingType>({
    h: 50,
    s: 50,
    l: 50,
  })

  const getProject = async (): Promise<void> => {
    openModal('loading')
    try {
      const token = await getAccessTokenSilently({
        audience: 'http://localhost:3001',
        scope: 'read:project',
      })

      const res = await API.get('project', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: projectId,
          mode: process.env.REACT_APP_MODE,
        },
      })

      if (res.data.result) {
        dispatch(editActions.updateLatestWatermark(res.data.latestWatermark))

        if (projectId === 'new') {
          closeModal()

          // 貸出版の場合はFTP情報をセット
          if (process.env.REACT_APP_MODE === '1') {
            const ftpSetting = res.data.ftpSetting
            ftpSetting.password = decryptFtpPassword(
              ftpSetting.password,
              ftpSetting.iv
            )

            dispatch(editActions.updateFtpSetting(ftpSetting))
          }

          dispatch(editActions.updatePhotoSize(res.data.photoSize))

          return
        }

        dispatch(
          editActions.setProject({
            project: res.data.project,
            watermark: res.data.watermark,
          })
        )

        setPhotoCount({
          photo: res.data.totalPhotoCnt,
          barcode: 0,
          uploaded: res.data.totalPhotoCnt,
        })
      } else {
        openDialog({
          type: 'projectNotFound',
          setDialogProps,
          onClickOk: () => {
            props.history.push('/')
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
    closeModal()
  }

  useEffect(() => {
    if (projectId === 'new') {
      dispatch(editActions.clearWatermarkInfo())
    }

    getProject()
  }, [])

  const openModal = React.useCallback((type: string): void => {
    const modalProps: ModalProps = {
      open: true,
      message: '',
      color: 'primary',
    }

    switch (type) {
      case 'loading':
        modalProps.message = 'データ取得中...'
        break
      case 'downloading':
        modalProps.message = 'ZIP作成中...'
        break
      case 'uploading':
        modalProps.message = 'サーバに保存中...'
        break
      default:
        modalProps.open = false
    }

    setModalProps(modalProps)
  }, [])

  const closeModal = React.useCallback((): void => {
    setModalProps({
      open: false,
      message: '',
      color: 'primary',
    })
  }, [])

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const toggleWatermarkOpen = useCallback((): void => {
    setIsWatermarkOpen(!isWatermarkOpen)
  }, [isWatermarkOpen])

  const toggleColorOpen = useCallback((): void => {
    setIsColorOpen(!isColorOpen)
  }, [isColorOpen])

  const execComposition = async (
    newWatermarkInfo: WatermarkInfo
  ): Promise<void> => {
    dispatch(editActions.updateWatermarkInfo(newWatermarkInfo))

    toggleWatermarkOpen()
  }

  const deleteAllPhotos = React.useCallback((): void => {
    dispatch(editActions.updateGroups([]))
    setPhotoCount({
      photo: 0,
      barcode: 0,
      uploaded: 0,
    })
  }, [dispatch])

  const addPhotos = React.useCallback(
    async (photos: File[]) => {
      // 読み込み上限を超える場合
      if (
        Number(process.env.REACT_APP_READ_MAX) <
        photos.length + photoCount.photo
      ) {
        openDialog({
          type: 'overPhotoCnt',
          setDialogProps,
          onClickOk: closeDialog,
        })
        return
      }

      setReadProggress(0)
      setIsReading(true)

      // 写真枚数のカウント
      const newPhotoCount = Object.assign({}, photoCount)
      newPhotoCount.photo += photos.length
      setPhotoCount(newPhotoCount)

      const newGroup = new Group()

      for (let i = 0; i < photos.length; i++) {
        const readRes = await readImageFile(photos[i], photoSize)

        newGroup.addPhoto(readRes)

        setReadProggress(((i + 1) / photos.length) * 100)
      }

      // 100%になった瞬間にモーダルが閉じると見栄えが悪いため1s後に消す
      setTimeout(() => {
        dispatch(editActions.updateGroups([...groups, newGroup]))

        setIsReading(false)
      }, 1000)
    },
    [dispatch, photoCount, photoSize, groups]
  )

  const checkGroupNameDup = React.useCallback((): boolean => {
    // グループ名の重複チェック
    const groupNameList: string[] = []
    const groupNameDupList: string[] = []

    for (const group of groups) {
      if (groupNameList.indexOf(group.name) < 0) {
        // 重複なし
        groupNameList.push(group.name)
      } else if (groupNameDupList.indexOf(group.name) < 0) {
        // 初重複
        groupNameDupList.push(group.name)
      }
    }

    if (0 < groupNameDupList.length) {
      // 重複しているグループをエラー表示
      const newGroups = groups.map((group, _idx) => {
        if (0 <= groupNameDupList.indexOf(group.name)) {
          group.isError = true
          return group.clone()
        }

        return group
      })
      dispatch(editActions.updateGroups(newGroups))

      const msg = (
        <span>
          重複しているグループ名があります。
          <br />
          {`[${groupNameDupList.join(', ')}]`}
        </span>
      )
      openDialog({
        type: 'groupNameDuplicated',
        setDialogProps,
        msg,
        onClickOk: closeDialog,
      })

      return false
    }

    return true
  }, [groups, dispatch])

  const execColorSetting = (colorSetting: ColorSettingType): void => {
    const newGroups = groups.map((group) => {
      const newGroup = group.clone()
      newGroup.photos.forEach((photo, _idx) => {
        photo.colorSetting = colorSetting
      })

      return newGroup
    })

    toggleColorOpen()

    setColorSetting(colorSetting)
    dispatch(editActions.updateGroups(newGroups))
  }

  const download = async (): Promise<void> => {
    if (isRevError || (await checkRevision())) {
      const msg = (
        <span>
          アプリが最新ではありません。
          <br />
          画面をリロードして再度お試しください。
        </span>
      )
      openDialog({
        type: 'notLatestVer',
        setDialogProps,
        msg,
        onClickOk: closeDialog,
      })

      return
    }

    openModal('downloading')

    if (!checkGroupNameDup()) {
      closeModal()
      return
    }

    const jsZip = new JSZip()

    let noNameCnt = 0
    let photoCnt = 0
    for (const group of groups) {
      let groupName = group.name
      if (groupName === '') {
        noNameCnt++
        groupName = `no_name_${paddingLeft(noNameCnt, '0', 2)}`
      }

      for (const photo of group.photos) {
        const dataSource = await photo.getDataSource(watermarkInfo)
        console.log(dataSource)

        jsZip.file(`${groupName}_${photo.name}.jpg`, dataSource, {
          base64: true,
        })

        photoCnt++
      }
    }

    const fd = new FormData()
    fd.append('photoCnt', JSON.stringify({ data: photoCnt }))
    fd.append('watermark', JSON.stringify({ data: watermarkInfo }))
    fd.append('isUpdated', isUpdated ? '1' : '0')
    fd.append('photoSize', JSON.stringify(photoSize))

    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
    })

    const result = await API.post('download', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch(() => {
      return { data: { result: false } }
    })

    closeModal()

    if (result.data.result) {
      jsZip.generateAsync({ type: 'blob' }).then((content: Blob) => {
        saveAs(content, `${projectName}.zip`)
      })

      if (watermarkInfo.src !== '') {
        dispatch(editActions.updateLatestWatermark(watermarkInfo.src))
      }

      dispatch(editActions.updateIsUpdated(false))
    } else {
      openDialog({
        type: 'errorDownload',
        setDialogProps,
        onClickOk: closeDialog,
      })
    }
  }

  const upload = async (): Promise<void> => {
    if (isRevError || (await checkRevision())) {
      const msg = (
        <span>
          アプリが最新ではありません。
          <br />
          画面をリロードして再度お試しください。
        </span>
      )
      openDialog({
        type: 'notLatestVer',
        setDialogProps,
        msg,
        onClickOk: closeDialog,
      })

      return
    }

    openModal('uploading')

    if (!checkGroupNameDup()) {
      closeModal()
      return
    }

    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
      scope: 'write:project',
    })

    const result = await API.post(
      'project',
      await createUploadData(
        projectId,
        projectName,
        photoSize,
        groups,
        watermarkInfo
      ),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch(() => {
      return { data: { result: false } }
    })

    closeModal()

    if (result.data.result) {
      setColorSetting({
        h: 50,
        s: 50,
        l: 50,
      })
      setProjectId(result.data.id)
      getProject()

      if (watermarkInfo.src !== '') {
        dispatch(editActions.updateLatestWatermark(watermarkInfo.src))
      }

      history.push(`/#/Edit/${result.data.id}`)
    } else {
      openDialog({
        type: 'errorUpload',
        setDialogProps,
        onClickOk: closeDialog,
      })
    }
  }

  const ftpUpload = async (
    host: string,
    port: string,
    user: string,
    password: string,
    dir: string
  ): Promise<{ code: number; msg: string }> => {
    const fd = new FormData()

    // 入力されたパスワードの暗号化
    let encryptedPassword = ''
    let iv = Buffer.from('')
    if (process.env.REACT_APP_CRYPTO_KEY) {
      // IV を生成
      iv = crypto.randomBytes(16)
      const cipher = crypto.createCipheriv(
        'aes-256-cbc',
        Buffer.from(process.env.REACT_APP_CRYPTO_KEY, 'base64'),
        iv
      )
      encryptedPassword = cipher.update(password, 'utf8', 'hex')
      encryptedPassword += cipher.final('hex')
    }

    fd.append(
      'ftpInfo',
      JSON.stringify({
        host,
        port,
        user,
        password: encryptedPassword,
        dir,
      })
    )
    fd.append('iv', iv.toString('base64'))

    let noNameCnt = 0
    for (const group of groups) {
      let groupName = group.name
      if (groupName === '') {
        noNameCnt++
        groupName = `no_name_${paddingLeft(noNameCnt, '0', 2)}`
      }

      const offset = group.isHeadBarcode ? 0 : 1
      await Promise.all(
        group.photos.map(async (photo, index) => {
          const blob = await photo.getDataBlob(watermarkInfo)

          fd.append(
            'photos[]',
            blob,
            group.name + '_' + paddingLeft(index + offset, '0', 3)
          )
        })
      )
    }

    fd.append('isUpdated', isUpdated ? '1' : '0')
    fd.append('photoSize', JSON.stringify(photoSize))

    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
      scope: 'write:project',
    })

    const res = await API.post('project/ftp', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })

    if (res.data.code === 0) {
      // 成功したら更新なしへ
      dispatch(editActions.updateIsUpdated(false))
    }

    return res.data
  }

  const backToList = React.useCallback((): void => {
    props.history.push('/')
  }, [props.history])

  return (
    <div>
      <Header
        photoCount={photoCount}
        checkGroupNameDup={checkGroupNameDup}
        onPhotoCountChange={setPhotoCount}
        onDeleteAllPhotos={deleteAllPhotos}
        onHsvClick={toggleColorOpen}
        onAddPhotos={addPhotos}
        onBackToList={backToList}
        onWatermarkClick={toggleWatermarkOpen}
        onDownload={download}
        onUpload={upload}
        onFtpUpload={ftpUpload}
      />
      <Content />
      <Dropzone onAddPhotos={addPhotos} />
      <ModalWithLinearProgress
        open={isReading}
        progress={readProggress}
        message="読込中..."
        color="primary"
      />
      <Modal {...modalProps} />
      <Dialog {...dialogProps} />
      <WatermarkSetting
        open={isWatermarkOpen}
        onClose={toggleWatermarkOpen}
        onExecComposition={execComposition}
      />
      <ColorSetting
        open={isColorOpen}
        src={groups.length === 0 ? '' : groups[0].photos[0].src}
        colorSetting={colorSetting}
        onApply={execColorSetting}
        onClose={toggleColorOpen}
      />
    </div>
  )
}

export default Edit
