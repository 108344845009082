import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
  onAddPhotos: (photos: File[]) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '200px',
      marginTop: '15px',
      textAlign: 'center',
    },
    dropzone: {
      width: '100%',
      height: '100%',
      border: '2px dashed #c3c3c3',
      borderRadius: '5px',
      '&:focus': {
        outline: 'none',
      },
    },
    dragged: {
      background: 'rgba(255, 255, 255, 0.15)',
      border: '2px solid #c3c3c3',
    },
    explain: {
      fontSize: 'xx-large',
      color: '#c3c3c3',
      paddingTop: '70px',
    },
  })
)

const Dropzone: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [isDragEnter, setIsDragEnter] = useState(false)

  const { getRootProps } = useDropzone({
    noClick: true,
    accept: ['image/jpeg', 'image/png', 'image/gif'],
    maxFiles: 1000,
    onDragEnter: () => {
      setIsDragEnter(true)
    },
    onDragLeave: () => {
      setIsDragEnter(false)
    },
    onDropAccepted: (acceptedFiles: File[]) => {
      props.onAddPhotos(acceptedFiles)
      setIsDragEnter(false)
    },
  })

  return (
    <div className={['container', classes.root].join(' ')}>
      <div
        {...getRootProps({
          className: [
            classes.dropzone,
            isDragEnter ? classes.dragged : '',
          ].join(' '),
        })}
      >
        <div className={classes.explain}>ここに画像をドラッグ</div>
      </div>
    </div>
  )
}

export default Dropzone
